<template>
    <footer class="footer-block" v-once>
        <div class="footer-left">
          <div class="footer-logo">
            <router-link :to="{name:'Home', params: { lang: this.$i18n.locale }}" @click.native="updateDataSwitch" routeTo="/" data-same-link="0">
                <div class="logo-block">
                  <img  width="60" height="69" :src='this.$pathprod+"images/home/Logo-footer.svg"' alt="Logo-footer">
                </div>
            </router-link>
          </div>
        </div>
        <div class="footer-right">
            <div class="list-partner">
              <a class="list-item" href="https://fr.linkedin.com/company/cdc-investissement-immobilier" rel="noopener" target="_blank"><img :src='this.$pathprod+"images/logo/logo-CDC-Invest-Immo.svg"' alt="CDC-Invest-Immo" width="97" height="16"></a>
              <a class="list-item" href="https://www.allianz.fr/" rel="noopener" target="_blank"><img :src='this.$pathprod+"images/logo/logo-allianz.svg"' alt="Allianz" width="56" height="14"></a>
              <a class="list-item" href="https://mabanque.bnpparibas/" rel="noopener" target="_blank"><img :src='this.$pathprod+"images/logo/logo-bnp-paribas.svg"' alt="BNP paribas" width="62" height="13"></a>
              <a class="list-item" href="https://www.emerige.com/" rel="noopener" target="_blank"><img :src='this.$pathprod+"images/logo/logo-emerige.svg"' alt="Emerige" width="26" height="13"></a>
              <router-link :to="{name:'MentionsLegales', params: { lang: this.$i18n.locale }}" class="lien-legale">Mentions légales</router-link>
            </div>
        </div>
    </footer>
</template>

<script>
// import Item from '@/components/Item.vue';
export default {
  name: 'Footer',
  props: {
    msg: String,
  },
  methods:{
    updateDataSwitch(e){
      let valHref = e.target.parentNode.parentNode.getAttribute('routeTo');
      let valHrefID = e.target.parentNode.parentNode.getAttribute('data-same-link');
      document.querySelector('#switch').setAttribute('lastUrl',valHref)
      document.querySelector('#switch').setAttribute('idUrl',valHrefID)
    }
  }
}
</script>